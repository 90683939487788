<template>
  <div>
    <title>Data TPB 23</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data TPB 23
        <br>
        <h4>Berikut list daftar data master TPB 23</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
      <div class="col-md-12">
        <div class="box box-danger">
          <div class="box-header with-border">
            <h3 class="box-title">Filter data</h3>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-xs-3">
               Show &nbsp; : 
                <select
                  v-model="pageSize"
                  @change="handlePageSizeChange($event)"
                >
                  <option v-for="size in pageSizes" :key="size" :value="size">
                    {{ size }}
                  </option>
                </select>
                results
              </div>
              <div class="col-xs-5">
                <input
                  type="text"
                  autocomplete="off"
                  placeholder="Cari berdasarkan Nomor AJU"
                  class="form-control"
                  v-model="namesearch"
                />
              </div>
              <div class="col-xs-1">
                  <button @click="searchdata()" type="button" class="btn btn-info">
                    <i class="fa fa-search"></i>
                  </button>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
      </div>
      <div v-if="loading" class="load">
       <img width="130" src="@/assets/loader.gif" alt="">
      </div>
      <br>

      <div class="row">
        <div class="col-xs-12">
            <div class="box box-primary">
                <div class="box-header">
                    <!-- <h3 class="box-title">Data Supplier</h3> -->
                </div>
                
                <!-- /.box-header -->
                <div class="box-body">
                    <div class="table-responsive">
                        <table id="mydata" class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                  <th>No.</th>
                                  <!-- <th>Idx</th> -->
                                  <th>No. AJU</th>
                                  <th>No. Daftar</th>
                                  <th>Tgl. Daftar</th>
                                  <th>Nama Pengirim</th>
                                  <th>Nama Pemasok</th>
                                  <th>Nama Penerima Barang</th>
                                  <th>Nama Pemilik</th>
                                  <th>Nama Pengangkut</th>
                                  <th>Nama Pengusaha</th>
                                  <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(classdata,index) in list_paged" :key="classdata.ID">
                                <td v-if="nmsrc">{{ index + 1 + (page - 1) * pageSize }}</td>
                                <td v-else>1</td>
                                <!-- <td>{{ classdata.id }}</td> -->
                                <td>{{ classdata.NOMOR_AJU }}</td>
                                <td>{{ classdata.NOMOR_DAFTAR }}</td>
                                <td>{{ classdata.TANGGAL_DAFTAR }}</td>
                                <td>{{ classdata.NAMA_PENGIRIM }}</td>
                                <td>{{ classdata.NAMA_PEMASOK }}</td>
                                <td>{{ classdata.NAMA_PENERIMA_BARANG }}</td>
                                <td>{{ classdata.NAMA_PEMILIK }}</td>
                                <td>{{ classdata.NAMA_PENGANGKUT }}</td>
                                <td>{{ classdata.NAMA_PENGUSAHA }}</td>
                                <td>
                                  <router-link :to="'/add-penerimaanbarang/' + classdata.ID">
                                     <button title="Tambah Penerimaan Barang" class="btn btn-info">
                                      <i class="fa fa-plus"></i> Penerimaan
                                    </button>
                                  </router-link> <br><p></p>
                                  <router-link :to="'/add-finishgood/' + classdata.ID">
                                     <button title="Finish Good Barang" class="btn btn-success">
                                      <i class="fa fa-plus"></i> Finish Good
                                    </button>
                                  </router-link>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                       <div v-if="nmsrc">
                       <v-pagination
                            v-model="page"
                            :pages="count"
                            :range-size="1"
                            active-color="#DCEDFF"
                            @update:modelValue="handlePageChange"
                        />
                        </div>
                        <div v-else></div>
                    </div>
                </div>
                <!-- /.box-body -->
            </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";  

import swal from 'sweetalert';
import axios from 'axios'

export default {
  name: 'klasifikasiartikel',
    components: {
        VPagination,
    },
  data() {
    return {
      isShow:false,
      nmsrc:true,
      loading: false,
      namesearch: '',
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize:5,
      pageSizes: [5, 10, 25, 50],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    searchdata() {
      this.loading = true
      this.nmsrc = true
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({name:'tpb23'})
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/tpb/getalldatatpb_header?cari=" + this.namesearch+"&length="+this.pageSize+"&kode_dokumen_pabean=23";
        const urlAPIget = this.$apiurl + "tpb/getalldatatpb_header?cari=" + this.namesearch+"&length="+this.pageSize+"&kode_dokumen_pabean=23";
          axios
          .get(urlAPIget, {params, headers: {Authorization:tokenlogin}})
          .then((resp) => {
            this.list_paged = resp.data.data
            this.count = resp.data.datatotalcount;
            this.loading = false
            this.nmsrc = false
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Peringatan",
              text: err.response.data.pesan,
            }).then(function(isConfirm) {
              if (isConfirm) {
                window.location.href="/"
              }
            })
          })
      }
    },
    async fetchData () {
      this.loading = true
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/tpb/getalldatatpb_header?length="+this.pageSize+"&page="+this.page+"&kode_dokumen_pabean=23";
      const urlAPIget = this.$apiurl + "tpb/getalldatatpb_header?length="+this.pageSize+"&page="+this.page+"&kode_dokumen_pabean=23";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
      .get(urlAPIget, { headers: headers })
      .then((resp) => {
        // console.log(resp);
        this.list_paged = resp.data.data
        this.count = resp.data.datatotalcount
        this.loading = false
      })
      .catch((err) => {
        console.log(err.response)
        swal({
          icon: "warning",
          title: "Peringatan",
          text: err.response.data.pesan,
        }).then(function(isConfirm) {
          if (isConfirm) {
            window.location.href="/"
          }
        });
        
      })
    }
  },
}
</script>
<style>
.load{
  width: 20%;
  margin: 0 auto;
}
</style>